<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_locations = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const locations = ref([])

onMounted( async () => {
    get_locations()
})

const get_locations = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtiger/get-locations')
    .then(response => {
        console.log(response.data)
        locations.value = response.data
        loader.value = false
    })
}

const location_details = (event) => {
    console.log(event.data)
    toast.add({severity:'info', summary:'Location Details', detail: event.data.name, life: 3000})
}


</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <div class="col-12 mb-2">
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_locations['global'].value" placeholder="Suche" style="width: 300px" />
                    </span>
                </template>
            </Toolbar>
        </div>
        <DataTable v-model:filters="filter_locations" :value="locations" @row-click="location_details" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <Column field="id" header="Location-ID" sortable style="width: 150px">
                <template #body="slotProps">
                    <Chip class="pl-0 pr-3 mr-2">
                        <span class="bg-primary bproduct-circle w-2rem h-2rem flex align-items-center justify-content-center"><i class="pi pi-map-marker" /></span>
                        <span class="ml-2 font-medium">{{slotProps.data.id}}</span>
                    </Chip>
                </template>
            </Column>
            <Column field="name" header="Location" sortable>
                <template #body="slotProps">
                    <span class="font-bold">{{slotProps.data.name}}</span>
                </template>
            </Column>
            <Column header="Status">
                <template #body="slotProps">
                    <span v-if="slotProps.data.status != 1" class="p-tag p-tag-success">Active</span>
                    <span v-else class="p-tag p-tag-danger">Inactive</span>
                </template>
            </Column>
            <Column style="width: 100px">
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-mr-2" @click="location_details(slotProps)" />
                </template>
            </Column>
        </DataTable>
    </BlockUI>
</template>