import {
    Bars3Icon,
    CalendarIcon,
    HomeIcon,
    ArrowUpIcon,
    ArrowUpOnSquareIcon,
    MagnifyingGlassCircleIcon,
    MapIcon,
    MegaphoneIcon,
    UserGroupIcon,
    XMarkIcon,
    InboxIcon,
    UsersIcon,
    CogIcon,
    DocumentIcon,
    QuestionMarkCircleIcon,
    ArrowLeftOnRectangleIcon,
    ArrowDownTrayIcon,
    EnvelopeIcon,
    BellIcon,
    EnvelopeOpenIcon, MagnifyingGlassIcon, Bars3BottomLeftIcon, BookOpenIcon, ClipboardDocumentIcon, CloudIcon, ShoppingCartIcon, ShoppingBagIcon, TruckIcon, CalculatorIcon, MapPinIcon
} from '@heroicons/vue/24/outline';

export default [
    { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: false, order: 0 },
    { name: 'Products', href: '/dashboard/Products', icon: ShoppingBagIcon, current: false, order: 4 },
    { name: 'Distribution', href: '/dashboard/Distribution', icon: TruckIcon, current: false, order: 2},
    { name: 'Locations', href: '/dashboard/Locations', icon: MapPinIcon, current: false, order: 3 },
    { name: 'Retail', href: '/dashboard/Retail', icon: ShoppingCartIcon, current: false, order: 1 },
    { name: 'Campaigns', href: '/dashboard/Campaigns', icon: MegaphoneIcon, current: false, order: 5 },
    { name: 'Purchase', href: '/dashboard/Purchase', icon: CalculatorIcon, current: false, order: 6 },
    { name: 'Settings', href: '/dashboard/Settings', icon: CogIcon, current: false, order: 7 }
]
;