<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_retail = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const retail = ref([])
const expanded_products = ref([])
const locations = ref([])
const location = ref(null)
const warnings = [
    {name: 'Stock depletion', code: 1},
    {name: 'Stock nearing depletion', code: 2},
    {name: 'Stock stand-still', code: 3},
    {name: 'Product repricing', code: 4},
    {name: 'Product runaways', code: 5},
    {name: 'Product flops', code: 6},
]
const warning = ref(null)
const transactions_report = ref([])
const transactions_report_dialog = ref(false)

onMounted( async () => {
    get_retail()
    get_locations()
})

const get_retail = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtiger/get-retail')
    .then(response => {
        console.log(response.data)
        retail.value = response.data
        loader.value = false
    })
}


const get_locations = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtiger/get-locations')
    .then(response => {
        console.log(response.data)
        locations.value = response.data
        loader.value = false
    })
}

const get_transaction_report = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtiger/get-transactions-report')
    .then(response => {
        console.log(response.data)
        transactions_report.value = response.data
        loader.value = false
    })
}

const retail_details = (event) => {
    console.log(event.data)
    toast.add({severity:'info', summary:'Retail Details', detail: event.data.id, life: 3000})
}

const retail_report = () => {
    console.log('Retail Report')
    get_transaction_report()
    transactions_report_dialog.value = true
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2});
}

const format_swiss_number = (value) => {
    return Number(value).toLocaleString('de-CH', {maximumFractionDigits: 2});
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <div class="col-12 mb-2">
            <Toolbar>
                <template #start>
                    <Dropdown v-model="location" :options="locations" optionLabel="name" placeholder="Filter by Locations" class="mr-2" />
                    <Dropdown v-model="warning" :options="warnings" optionLabel="name" placeholder="Filter by Warnings" class="mr-2" />
                    <span class="p-input-icon-left mr-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_retail['global'].value" placeholder="Suche" style="width: 300px" />
                    </span>
                    <Button label="Report" icon="pi pi-chart-bar" @click="retail_report()" />
                </template>
            </Toolbar>
        </div>
        <div rounded class="overflow-hidden shadow-3 border-200">
            <DataTable v-model:expandedRows="expanded_products" tableStyle="min-width: 50rem" :virtualScrollerOptions="{ itemSize: 10 }" :value="retail" dataKey="transaction_date" :rows="10" responsiveLayout="scroll" :rowsPerPageOptions="[10,20,50]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                <Column expander style="width: 5rem" />
                <Column field="transaction_date" header="Date" style="width: 25%">
                    <template #body="slotProps">
                        <span class="font-bold mr-2">{{ format_swiss_date(slotProps.data.transaction_date) }}</span><span>({{ slotProps.data.transaction_day }})</span>
                    </template>
                </Column>
                <Column field="summary.total_quantity" header="Total Volume" style="width: 15%">
                    <template #body="slotProps">
                        {{ format_swiss_number(Math.abs(slotProps.data.summary.total_quantity)) }}
                    </template>
                </Column>
                <Column field="summary.total_turnover" header="Total Revenue" style="width: 15%">
                    <template #body="slotProps">
                        {{ format_currency(slotProps.data.summary.total_turnover) }}
                    </template>
                </Column>
                <Column field="summary.total_restock" header="Total Restock" style="width: 15%">
                    <template #body="slotProps">
                        {{ format_swiss_number(slotProps.data.summary.total_restock) }}
                    </template>
                </Column>
                <Column header="Total Products" style="width: 15%">
                    <template #body="slotProps">
                        {{ format_swiss_number(slotProps.data.summary.items.length) }}
                    </template>
                </Column>
                <Column header="Total Locations" style="width: 15%">
                    <template #body="slotProps">
                        {{ format_swiss_number(slotProps.data.summary.locations.length) }}
                    </template>
                </Column>
                <template #expansion="slotProps">
                    <div class="p-3 bg-blue-50 rounded shadow-2">
                        <TabView>
                            <TabPanel :header="'Products ('+slotProps.data.summary.items.length+')'">
                                <DataTable :value="slotProps.data.summary.items" :rows="10" responsiveLayout="scroll" :rowHover="true" :virtualScrollerOptions="{ itemSize: 10 }" :rowsPerPageOptions="[10,20,50]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                                    <Column field="item_no" header="Item" sortable>
                                        <template #body="slotProps">
                                            <span class="font-bold">{{ slotProps.data.item_no }}</span>
                                        </template>
                                    </Column>
                                    <Column field="total_quantity" header="Total Volume" sortable>
                                        <template #body="slotProps">
                                            {{ format_swiss_number(Math.abs(slotProps.data.total_quantity)) }}
                                        </template>
                                    </Column>
                                    <Column field="total_turnover" header="Total Revenue" sortable>
                                        <template #body="slotProps">
                                            {{ format_currency(slotProps.data.total_turnover) }}
                                        </template>
                                    </Column>
                                    <Column field="total_restock" header="Total Restock" sortable>
                                        <template #body="slotProps">
                                            {{ format_swiss_number(slotProps.data.total_restock) }}
                                        </template>
                                    </Column>
                                </DataTable>
                            </TabPanel>
                            <TabPanel :header="'Locations ('+slotProps.data.summary.locations.length+')'">
                                <DataTable :value="slotProps.data.summary.locations" :rows="10" responsiveLayout="scroll" :rowHover="true" :virtualScrollerOptions="{ itemSize: 10 }" :rowsPerPageOptions="[10,20,50]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                                    <Column field="location_code" header="Location" sortable>
                                        <template #body="slotProps">
                                            <span class="font-bold">{{ slotProps.data.location_code }}</span>
                                        </template>
                                    </Column>
                                    <Column field="total_quantity" header="Total Volume" sortable>
                                        <template #body="slotProps">
                                            {{ format_swiss_number(Math.abs(slotProps.data.total_quantity)) }}
                                        </template>
                                    </Column>
                                    <Column field="total_turnover" header="Total Revenue" sortable>
                                        <template #body="slotProps">
                                            {{ format_currency(slotProps.data.total_turnover) }}
                                        </template>
                                    </Column>
                                    <Column field="total_restock" header="Total Restock" sortable>
                                        <template #body="slotProps">
                                            {{ format_swiss_number(Math.abs(slotProps.data.total_restock)) }}
                                        </template>
                                    </Column>
                                </DataTable>
                            </TabPanel>
                        </TabView>
                    </div>
                </template>
            </DataTable>
        </div>
    </BlockUI>
    <!------------------------------------------------------>
    <!---------------- Transactions Report ----------------->
    <!------------------------------------------------------>
    <Dialog v-model:visible="transactions_report_dialog" :style="{width: '800px'}" header="Transactions Report" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <TreeTable :value="transactions_report">
                    <Column field="name" header="Name" expander></Column>
                    <Column field="count" header="Total"></Column>
                </TreeTable>
            </div>
        </div>
    </Dialog>
</template>